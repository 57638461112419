import React, {useEffect, useState} from "react";
import Prismic from "@prismicio/client";

export function Gallery() {
  const [list, setList] = useState([]);

  useEffect(async () => {
    const api = await Prismic.client("https://shippo.prismic.io/api/v2");
    const doc = await api.getByID("YYiusBEAACQAQ9Po");
    const images = doc.data.body[0].items.map((item)=>{
      return item.image.url;
    })
    setList(images);
  }, [])

  return <>
    {list.map((image)=>(
      <div className="gallery-item" style={{
        backgroundImage: `url(${image})`
      }}></div>
    ))}
  </>
}
