import ReactDom from "react-dom";
import {NewsList} from "./components/NewsList";
import {NewsDetailList} from "./components/NewsDetailList";
import {Gallery} from "./components/Gallery";

const Swiper = window.Swiper;

if (Swiper) {
  const swiper = new Swiper(".swiper", {
    effect: "fade",
    speed: 800,
    slidesPerView: 1,
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: 4000,
    }
  });
}


const $newsList = document.querySelector(".news-list");
if ($newsList) {
  ReactDom.render(<NewsList/>, $newsList);
}

const $newsDetailList = document.querySelector(".news-detail-list");
if ($newsDetailList) {
  ReactDom.render(<NewsDetailList/>, $newsDetailList);
}

const $gallery = document.querySelector(".gallery-list");
if($gallery){
  ReactDom.render(<Gallery />, $gallery);
}
