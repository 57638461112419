import React, {useEffect, useState} from "react";
import Prismic from "@prismicio/client";
import PrismicDOM from "prismic-dom";

export function NewsList() {
  const [list, setList] = useState([]);

  useEffect(async () => {
    const api = await Prismic.client("https://shippo.prismic.io/api/v2");
    const res = await api.query(
      [Prismic.Predicates.at('document.type', 'news'),
      ],
      {pageSize: 1, page: 1, orderings: "[my.news.date desc]"}
    )
    const list = res.results.map((d) => {
      const date = new Date(d.data.date);
      return {
        id: d.id,
        title: d.data.title?.[0]?.text ?? "NO TITLE",
        date: `${date.getFullYear()}.${date.getMonth()+1}.${date.getDate()}`,
      }
    });
    setList(list);

  }, [])

  return <>
    {list.map((d) => (
      <div className="news-item" key={d.id}>
        <div className="news-date">{d.date}</div>
        <div className="news-name">{d.title}</div>
        <a href={`news.html`} className="news-more">もっと見る≫</a>
      </div>
    ))}
  </>
}
