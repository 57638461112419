import React, {useEffect, useState} from "react";
import Prismic from "@prismicio/client";
import PrismicDOM from "prismic-dom";
import qs from "query-string";

export function NewsDetailList() {
  const [inited, setInited] = useState(false);
  const [page, setPage] = useState(null);
  const [list, setList] = useState([]);

  useEffect(async () => {
    const queryParams = qs.parse(location.search);
    const page = queryParams.page ?? "1";
    const api = await Prismic.client("https://shippo.prismic.io/api/v2");
    const res = await api.query(
      [Prismic.Predicates.at('document.type', 'news'),
      ],
      {pageSize: 5, page: parseInt(page, 10), orderings: "[my.news.date desc]"}
    )
    const list = res.results.map((d) => {
      const html = PrismicDOM.RichText.asHtml(d.data.body);
      const date = new Date(d.data.date);
      return {
        id: d.id,
        title: d.data.title?.[0]?.text ?? "NO TITLE",
        date: `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`,
        body: html,
      }
    });
    setList(list);
    setPage({
      page: res.page,
      prev: res.prev_page ? res.page - 1 : null,
      next: res.next_page ? res.page + 1 : null,
    })

    setTimeout(()=>{
      const target = document.getElementById(queryParams.id);
      if(target){
        const box = target.getBoundingClientRect();
        window.scroll(0, box.top)
      }
      setInited(true);
    }, 100);

  }, [])

  return <div className="news-detail-list-inner" data-inited={inited}>
    <div className="news-title">NEWS</div>
    {list.map((d) => {
      return <section className="news-detail" key={d.id} id={d.id}>
        <p className="news-detail-date">{d.date}</p>
        <h2 className="news-detail-title">{d.title}</h2>
        <div className="news-detail-body" dangerouslySetInnerHTML={{__html: d.body}}/>
        <div className="line"/>
      </section>
    })}
    {page ? <div className="news-detail-nav">
      {page.prev !== null ? <a href={`/news.html?page=${page.prev}`} className="news-detail-prev">&lt; 前へ</a> : null}
      <div className="news-detail-space"/>
      {page.next !== null ? <a href={`/news.html?page=${page.next}`} className="news-detail-next">次へ &gt;</a> : null}
    </div> : null}
  </div>
}
